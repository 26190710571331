import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import {
  Layout,
  SplitScroll,
  ScrollWrapper,
  Header,
  SEO,
  MobileHeader,
  HeaderBanner,
} from '../components'

import DoubleHigh from '../views/doubleHigh'
import AmenityOne from '../views/amenityOne'
import AmenityTwo from '../views/amenityTwo'
import AmenityThree from '../views/amenityThree'
import AmenityFour from '../views/amenityFour'
import Neighbourhood from '../views/neighbourhood'
import Floorplans from '../views/floorplans'
import Blog from '../views/blog'
import Gallery from '../views/gallery'
import Form from '../views/form'
// import Contact from '../views/contact'
import Company from '../views/company'
import BigYear from '../views/bigYear'
import Investors from '../views/investors'

const contents = [
  <DoubleHigh />,
  <AmenityOne />,
  // <AmenityTwo />,
  <AmenityThree />,
  <AmenityFour />,
  <Neighbourhood />,
  <Floorplans />,
  // <Blog />,
  <Gallery />,
  // <Form />,
  // <Contact />,
  <Investors />,
  <Company />,
  <div />,
]

const bigYearContents = [
  <DoubleHigh />,
  <BigYear />,
  <AmenityOne />,
  <AmenityTwo />,
  <AmenityThree />,
  <AmenityFour />,
  <Neighbourhood />,
  <Floorplans />,
  <Blog />,
  <Gallery />,
  <Form />,
  <Investors />,
  <Company />,
  <div />,
]

const reverseContents = contents.slice(0, 10000).reverse()
const reverseBigYearContents = bigYearContents.slice(0, 10000).reverse()

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      index: 0,
      path: '',
      navigatePath: '',
      absorbRoute: false,
      showRight: true,
      mounted: false,
      modalScrollPosition: 0,
      modalActive: false,
      modalShown: false,
    }
  }

  //monitor scrolling to initiate popup
  monitorScroll = () => {
    if (
      window.scrollY / document.documentElement.scrollHeight > 0.2 &&
      !this.state.modalShown
    ) {
      this.setState({
        modalShown: true,
        modalActive: true,
        modalScrollPosition: window.scrollY,
      })
    }
  }

  componentDidMount = () => {
    let IE = false
    if (/MSIE 10/i.test(navigator.userAgent)) {
      // This is internet explorer 10
      IE = true
    }

    if (
      /MSIE 9/i.test(navigator.userAgent) ||
      /rv:11.0/i.test(navigator.userAgent)
    ) {
      // This is internet explorer 9 or 11
      IE = true
    }

    if (/Edge\/\d./i.test(navigator.userAgent)) {
      // This is Microsoft Edge
      IE = true
    }
    let w = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    )
    if (IE) {
      w = 0
    }
    if (w < 1024) {
      this.setState({ showRight: false })
    }
    this.setState({ mounted: true })
    window.addEventListener('scroll', this.monitorScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.monitorScroll)
  }

  componentDidUpdate() {
    if (this.state.showRight) {
      if (
        this.props.location.pathname !== this.state.path &&
        !this.state.absorbRoute
      ) {
        this.setState({
          navigatePath: this.props.location.pathname,
          path: this.props.location.pathname,
        })
        //window.LassoCRM.tracker.track()
      }
      if (
        this.state.absorbRoute &&
        this.props.location.pathname !== this.state.path
      ) {
        this.setState({
          path: this.props.location.pathname,
          absorbRoute: false,
        })
        //window.LassoCRM.tracker.track()
      }
    }
  }

  updateIndex = index => {
    this.setState({ index: index })
  }

  updateRoute = route => {
    if (route.indexOf('/building') !== -1 && !this.state.modalShown) {
      this.setState({ modalShown: true, modalActive: true })
    }
    if (route !== this.state.path) {
      this.setState({ absorbRoute: true }, () => {
        this.props.navigate(
          process.env.NODE_ENV !== 'development' ? '/nude' + route : route
        )
      })
    }
  }

  forceRoute = route => {
    this.setState({ absorbRoute: false, path: '' }, () => {
      this.props.navigate(route)
    })
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          {
            wordpressPage(slug: { eq: "big-year" }) {
              acf {
                heading
                big_year_active
              }
            }
          }
        `}
        render={data => (
          <Layout>
            <SEO />
            {this.state.mounted && (
              <>
                {data.wordpressPage.acf.big_year_active && (
                  <HeaderBanner
                    isMobile={!this.state.showRight}
                    forceRoute={this.forceRoute}
                  />
                )}
                <MobileHeader
                  bigYearActive={data.wordpressPage.acf.big_year_active}
                />
                <Header
                  index={this.state.index}
                  path={this.state.path}
                  isMobile={!this.state.showRight}
                  forceRoute={this.forceRoute}
                  bigYearActive={data.wordpressPage.acf.big_year_active}
                />
                <ScrollWrapper
                  path={this.state.navigatePath}
                  content={
                    data.wordpressPage.acf.big_year_active
                      ? bigYearContents
                      : contents
                  }
                  updateIndex={this.updateIndex}
                  updateRoute={this.updateRoute}
                  bigYearActive={data.wordpressPage.acf.big_year_active}
                >
                  <SplitScroll
                    content={
                      data.wordpressPage.acf.big_year_active
                        ? bigYearContents
                        : contents
                    }
                    reverseContents={
                      data.wordpressPage.acf.big_year_active
                        ? reverseBigYearContents
                        : reverseContents
                    }
                    index={this.state.index}
                    showRight={this.state.showRight}
                    forceRoute={this.forceRoute}
                  />
                </ScrollWrapper>
              </>
            )}
            {/* {this.state.modalActive && (
              <PopUp
                open={this.state.modalActive}
                close={() => {
                  this.setState({ modalActive: false }, () => {
                    if (
                      typeof window !== 'undefined' &&
                      this.state.modalScrollPosition !== 0
                    ) {
                      window.scrollTo(0, this.state.modalScrollPosition)
                    }
                  })
                }}
              />
            )} */}
          </Layout>
        )}
      />
    )
  }
}

export default IndexPage
