import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import {
  Box,
  ContentStack,
  FlexCol,
  FlexGrid,
  Gallery,
  StickerStamp,
} from '../../components'

const BlogOuter = props => (
  <StaticQuery
    query={graphql`
      {
        allWordpressPost(
          filter: { categories: { elemMatch: { name: { eq: "NUDE" } } } }
        ) {
          edges {
            node {
              slug
              featured_media {
                source_url
                localFile {
                  childImageSharp {
                    sizes(maxWidth: 1900, quality: 90) {
                      ...GatsbyImageSharpSizes_withWebp
                    }
                  }
                }
              }
              categories {
                name
              }
              title
              excerpt
            }
          }
        }
      }
    `}
    render={data => {
      let featuredBlogs = []
      let blogMedia = []
      data.allWordpressPost.edges.forEach(post => {
        if (
          post.node.categories &&
          post.node.categories[0] &&
          post.node.categories[1] &&
          (post.node.categories[0].name === 'NUDE' ||
            post.node.categories[1].name === 'NUDE') &&
          post.node.featured_media &&
          post.node.featured_media.localFile &&
          featuredBlogs.length < 3
        ) {
          featuredBlogs.push(post)
          blogMedia.push(post.node.featured_media)
        }
      })
      // if (featuredBlogs.length > 0) {
      //   this.setState({ featuredBlog: featuredBlogs[0] })
      // }

      return (
        <Blog featuredBlogs={featuredBlogs} blogMedia={blogMedia} {...props} />
      )
    }}
  />
)

class Blog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      featuredBlog: undefined,
    }
  }

  componentDidMount() {
    this.setState({ featuredBlog: this.props.featuredBlogs[0] })
  }

  render() {
    return (
      <Box
        height="100%"
        bg="bg.default"
        id={process.env.NODE_ENV !== 'development' ? '/nude/blog/' : '/blog/'}
      >
        <Box height="100%">
          <FlexGrid height="100%">
            {this.state.featuredBlog && (
              <FlexCol
                width={[1 / 1, null, null, 1 / 2]}
                height={['85vh', null, null, '100%']}
                flex={1}
                // is={Link}
                // to={'/blog/' + this.state.featuredBlog.node.slug}
              >
                {this.props.blogMedia && (
                  <Gallery
                    images={this.props.blogMedia}
                    active={this.props.active}
                    id="blog"
                    carouselChangeHook={index => {
                      this.setState({
                        featuredBlog: this.props.featuredBlogs[index],
                      })
                    }}
                    expandable={false}
                  />
                )}
              </FlexCol>
            )}
            <FlexCol
              justifyContent="center"
              alignItems="center"
              width={[1 / 1, null, null, 1 / 2]}
              order={[1, null, null, 0]}
            >
              <FlexCol
                mt={[64, null, null, 32]}
                mb={[64, null, null, 0]}
                mx={[32, null, null, 0]}
              >
                <FlexCol
                  width={1 / 1}
                  maxWidth={[480, null, null, 330]}
                  ml={[0, null, null, -48]}
                  mr={[-16, null, null, 0]}
                  mb={[-16, null, null, -24]}
                >
                  <StickerStamp title={`NUDE BLOG`} />
                </FlexCol>
                {this.state.featuredBlog && (
                  <ContentStack
                    heading={this.state.featuredBlog.node.title}
                    headingLink={
                      '/blog/' + this.state.featuredBlog.node.slug + '/'
                    }
                    body={this.state.featuredBlog.node.excerpt}
                    bodyLink={
                      '/blog/' + this.state.featuredBlog.node.slug + '/'
                    }
                    buttonText={`See more`}
                    buttonLink={'/nude/blog/'}
                    noNewWindow={true}
                  />
                )}
              </FlexCol>
            </FlexCol>
          </FlexGrid>
        </Box>
      </Box>
    )
  }
}

export default BlogOuter
