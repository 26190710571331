import React from 'react'

import { Box } from '../../../components'

import Visual from '../../../images/floorplan/floorplan.png'

import { FloorplanSvg, FloorplanImage, HoverPath, HoverRect } from './style'

import { FontString } from './fontString'

const InteractiveFloor = props => {
  return (
    <Box
      position="relative"
      width={1 / 1}
      maxWidth={640}
      height="60vh"
      mx="auto"
    >
      <Box height="100%">
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          height="100%"
        >
          <FloorplanImage src={Visual} />
        </Box>
        <Box position="absolute" top={0} left={0} width={1 / 1} height="100%">
          <Box
            position="absolute"
            top="50%"
            left={0}
            width={100 + '%'}
            pb={(530 / 570) * 100 + '%'}
            style={{ transform: 'translateY(-50%)', pointerEvents: 'none' }}
          >
            <svg
              width="74px"
              height="378px"
              width={(74 / 378) * 48}
              height={48}
              viewBox="0 0 74 378"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                position: 'absolute',
                top: '0',
                left: '0',
              }}
            >
              <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g
                  id="trueNorth"
                  transform="translate(5.000000, 0.000000)"
                  fill="#083D3E"
                >
                  <polygon
                    id="N"
                    points="54.9887327 122.656 45.2278198 122.656 24.4168168 60.5913273 18.5234354 40.8853333 18.5234354 122.656 6 122.656 6 0 17.9709309 0 36.9402523 62.9855135 42.4652973 82.8756757 42.4652973 0 54.9887327 0"
                  />
                  <g
                    id="Group"
                    transform="translate(0.000000, 156.000000)"
                    fillRule="nonzero"
                  >
                    <polygon
                      id="Path-2"
                      points="26.25 2 38.25 2 38.25 222 26.25 222"
                    />
                    <polygon
                      id="Path-3"
                      points="32 8.48528137 4.24264069 36.2426407 -4.24264069 27.7573593 32 -8.48528137 68.2426407 27.7573593 59.7573593 36.2426407"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </Box>
          <FloorplanSvg
            width="100%"
            height="100%"
            viewBox="0 0 554 600"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <style jsx>{`
                ${FontString};
              `}</style>
            </defs>
            <HoverPath
              d="M474.87139,258.569052 L474.87139,298.685524 L343.906588,298.685524 L343.906588,218.635607 L373.898736,218.635607 L373.898736,188.685524 L474.87139,188.685524 L513.906588,188.685524 L513.906588,258.569052 L474.87139,258.569052 Z"
              id="Unit10"
              className={
                props.activeFloor.unit_10_sold
                  ? 'sold'
                  : props.activeUnit === '10'
                    ? 'clicked'
                    : ''
              }
              onClick={() => {
                props.changeUnit('10', true)
              }}
            />
            <HoverPath
              d="M473.906588,39.9334443 L473.906588,0 L553.941787,0 L553.941787,116.803661 L513.906588,116.803661 L513.906588,188.685524 L344.906588,188.685524 L344.906588,39.9334443 L473.906588,39.9334443 Z"
              id="Unit09"
              className={
                props.activeFloor.unit_09_sold
                  ? 'sold'
                  : props.activeUnit === '09'
                    ? 'clicked'
                    : ''
              }
              onClick={() => {
                props.changeUnit('09', true)
              }}
            />
            <HoverRect
              id="Unit08"
              x="207.943682"
              y="39.9334443"
              width="136.962906"
              height="148.75208"
              className={
                props.activeFloor.unit_08_sold
                  ? 'sold'
                  : props.activeUnit === '08'
                    ? 'clicked'
                    : ''
              }
              onClick={() => {
                props.changeUnit('08', true)
              }}
            />
            <HoverPath
              d="M179.98944,188.68386 L38.9894404,188.68386 L38.9894404,78.8668885 L38.9894404,38.9334443 L105.9713,38.9334443 L105.9713,78.8668885 L207.98944,78.8668885 L207.98944,188.68386 L207.98944,221.628952 L179.98944,221.628952 L179.98944,188.68386 Z"
              id="Unit07"
              className={
                props.activeFloor.unit_07_sold
                  ? 'sold'
                  : props.activeUnit === '07'
                    ? 'clicked'
                    : ''
              }
              onClick={() => {
                props.changeUnit('07', true)
              }}
            />
            <HoverPath
              d="M179.98944,221.68386 L207.98944,221.68386 L207.98944,300.499168 L78.9786101,300.499168 L78.9786101,258.569052 L38.9894404,258.569052 L38.9894404,188.68386 L179.98944,188.68386 L179.98944,221.68386 Z"
              id="Unit06"
              className={
                props.activeFloor.unit_06_sold
                  ? 'sold'
                  : props.activeUnit === '06'
                    ? 'clicked'
                    : ''
              }
              onClick={() => {
                props.changeUnit('06', true)
              }}
            />
            <HoverPath
              d="M177.951805,332.445923 L207.98944,332.445923 L207.98944,410.31614 L78.9786101,410.31614 L78.9786101,369.384359 L38.9894404,369.384359 L38.9894404,300.499168 L177.951805,300.499168 L177.951805,332.445923 Z"
              id="Unit05"
              transform="translate(123.489440, 355.407654) scale(1, -1) translate(-123.489440, -355.407654) "
              className={
                props.activeFloor.unit_05_sold
                  ? 'sold'
                  : props.activeUnit === '05'
                    ? 'clicked'
                    : ''
              }
              onClick={() => {
                props.changeUnit('05', true)
              }}
            />
            <HoverPath
              d="M38.9894404,482.369384 L38.9894404,410.31614 L177.951805,410.31614 L177.951805,378.369384 L208,378.369384 L208,410.31614 L208,560.369384 L79.9786101,560.369384 L79.9786101,600 L0,600 L0,482.369384 L38.9894404,482.369384 Z"
              id="Unit04"
              className={
                props.activeFloor.unit_04_sold
                  ? 'sold'
                  : props.activeUnit === '04'
                    ? 'clicked'
                    : ''
              }
              onClick={() => {
                props.changeUnit('04', true)
              }}
            />
            <HoverRect
              id="Unit03"
              x="207.943412"
              y="411.314476"
              width="138.962906"
              height="148.75208"
              className={
                props.activeFloor.unit_03_sold
                  ? 'sold'
                  : props.activeUnit === '03'
                    ? 'clicked'
                    : ''
              }
              onClick={() => {
                props.changeUnit('03', true)
              }}
            />
            <HoverPath
              d="M513.957031,512.404318 L513.957031,555.312812 L447.729825,555.312812 L447.729825,516.560732 L346.906318,516.560732 L346.906318,407.560732 L513.957031,407.560732 L513.957031,512.404318 Z"
              id="Unit02"
              className={
                props.activeFloor.unit_02_sold
                  ? 'sold'
                  : props.activeUnit === '02'
                    ? 'clicked'
                    : ''
              }
              onClick={() => {
                props.changeUnit('02', true)
              }}
            />
            <HoverPath
              d="M344,377.499168 L344,298.998336 L474.640412,298.998336 L474.640412,337.40927 L513.906588,337.40927 L513.906588,407.499168 L474.640412,407.499168 L373.906588,407.499168 L373.906588,377.499168 L344,377.499168 Z"
              id="Unit01"
              className={
                props.activeFloor.unit_01_sold
                  ? 'sold'
                  : props.activeUnit === '01'
                    ? 'clicked'
                    : ''
              }
              onClick={() => {
                props.changeUnit('01', true)
              }}
            />
            <g
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
              style={{ pointerEvents: 'none' }}
            >
              <text
                id="01"
                fontFamily="'Knockout 27 A', Knockout-HTF27-JuniorBantamwt, Knockout"
                fontSize="36"
                fontWeight="500"
                letterSpacing="2.25"
                fill="#FFFFFF"
              >
                {props.activeFloor.unit_01_sold !== true && (
                  <tspan fontSize="56" x="415.11" y="370">
                    01
                  </tspan>
                )}
                {props.activeFloor.unit_01_sold === true && (
                  <tspan x="400" y="365">
                    SOLD
                  </tspan>
                )}
              </text>
              <text
                id="02"
                fontFamily="'Knockout 27 A', Knockout-HTF27-JuniorBantamwt, Knockout"
                fontSize="36"
                fontWeight="500"
                letterSpacing="2.25"
                fill="#FFFFFF"
              >
                {props.activeFloor.unit_02_sold !== true && (
                  <tspan fontSize="56" x="413.292" y="480">
                    02
                  </tspan>
                )}
                {props.activeFloor.unit_02_sold === true && (
                  <tspan x="400" y="475">
                    SOLD
                  </tspan>
                )}
              </text>

              <text
                id="03"
                fontFamily="'Knockout 27 A', Knockout-HTF27-JuniorBantamwt, Knockout"
                fontSize="36"
                fontWeight="500"
                letterSpacing="2.25"
                fill="#FFFFFF"
              >
                {props.activeFloor.unit_03_sold !== true && (
                  <tspan fontSize="56" x="264.112" y="501">
                    03
                  </tspan>
                )}
                {props.activeFloor.unit_03_sold === true && (
                  <tspan x="250" y="496">
                    SOLD
                  </tspan>
                )}
              </text>
              <text
                id="04"
                fontFamily="'Knockout 27 A', Knockout-HTF27-JuniorBantamwt, Knockout"
                fontSize="36"
                fontWeight="500"
                letterSpacing="2.25"
                fill="#FFFFFF"
              >
                {props.activeFloor.unit_04_sold !== true && (
                  <tspan fontSize="56" x="117.31" y="501">
                    04
                  </tspan>
                )}
                {props.activeFloor.unit_04_sold === true && (
                  <tspan x="102" y="496">
                    SOLD
                  </tspan>
                )}
              </text>
              <text
                id="05"
                fontFamily="'Knockout 27 A', Knockout-HTF27-JuniorBantamwt, Knockout"
                fontSize="36"
                fontWeight="500"
                letterSpacing="2.25"
                fill="#FFFFFF"
              >
                {props.activeFloor.unit_05_sold !== true && (
                  <tspan fontSize="56" x="117.112" y="370">
                    05
                  </tspan>
                )}
                {props.activeFloor.unit_05_sold === true && (
                  <tspan x="102" y="365">
                    SOLD
                  </tspan>
                )}
              </text>
              <text
                id="06"
                fontFamily="'Knockout 27 A', Knockout-HTF27-JuniorBantamwt, Knockout"
                fontSize="36"
                fontWeight="500"
                letterSpacing="2.25"
                fill="#FFFFFF"
              >
                {props.activeFloor.unit_06_sold !== true && (
                  <tspan
                    fontFamily="'Knockout 27 A', Knockout-HTF27-JuniorBantamwt, Knockout"
                    fontSize="56"
                    x="116.252"
                    y="265"
                  >
                    06
                  </tspan>
                )}
                {props.activeFloor.unit_06_sold === true && (
                  <tspan x="101" y="255">
                    SOLD
                  </tspan>
                )}
              </text>
              <text
                id="07"
                fontFamily="'Knockout 27 A', Knockout-HTF27-JuniorBantamwt, Knockout"
                fontSize="36"
                fontWeight="500"
                letterSpacing="2.25"
                fill="#FFFFFF"
              >
                {props.activeFloor.unit_07_sold !== true && (
                  <tspan fontSize="56" x="117.418" y="150">
                    07
                  </tspan>
                )}
                {props.activeFloor.unit_07_sold === true && (
                  <tspan x="102" y="145">
                    SOLD
                  </tspan>
                )}
              </text>
              <text
                id="08"
                fontFamily="'Knockout 27 A', Knockout-HTF27-JuniorBantamwt, Knockout"
                fontSize="36"
                fontWeight="500"
                letterSpacing="2.25"
                fill="#FFFFFF"
              >
                {props.activeFloor.unit_08_sold !== true && (
                  <tspan fontSize="56" x="264.234" y="130">
                    08
                  </tspan>
                )}
                {props.activeFloor.unit_08_sold === true && (
                  <tspan x="250" y="125">
                    SOLD
                  </tspan>
                )}
              </text>
              <text
                id="09"
                fontFamily="'Knockout 27 A', Knockout-HTF27-JuniorBantamwt, Knockout"
                fontSize="36"
                fontWeight="500"
                letterSpacing="2.25"
                fill="#FFFFFF"
              >
                {props.activeFloor.unit_09_sold !== true && (
                  <tspan fontSize="56" x="412.18" y="130">
                    09
                  </tspan>
                )}
                {props.activeFloor.unit_09_sold === true && (
                  <tspan x="400" y="125">
                    SOLD
                  </tspan>
                )}
              </text>
              <text
                id="10"
                fontFamily="'Knockout 27 A', Knockout-HTF27-JuniorBantamwt, Knockout"
                fontSize="36"
                fontWeight="500"
                letterSpacing="2.25"
                fill="#FFFFFF"
              >
                {props.activeFloor.unit_10_sold !== true && (
                  <tspan fontSize="56" x="415.11" y="265">
                    10
                  </tspan>
                )}
                {props.activeFloor.unit_10_sold === true && (
                  <tspan x="400" y="255">
                    SOLD
                  </tspan>
                )}
              </text>
            </g>
          </FloorplanSvg>
        </Box>
      </Box>
    </Box>
  )
}

export default InteractiveFloor
