import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import {
  Box,
  ContentStack,
  FlexCol,
  FlexGrid,
  StickerStamp,
  Gallery,
} from '../../components'

const AmenityTwo = props => (
  <StaticQuery
    query={graphql`
      {
        wordpressPage(slug: { eq: "amenity-two" }) {
          acf {
            heading
            body
            starting_price
            icon {
              source_url
            }
            images {
              localFile {
                childImageSharp {
                  sizes(maxWidth: 1900, quality: 90) {
                    ...GatsbyImageSharpSizes_withWebp
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={page => (
      <>
        {page &&
          page.acf && (
            <Box height="100%" bg="bg.default">
              <Box height="100%">
                <FlexGrid width={1 / 1} height="100%">
                  <FlexCol
                    justifyContent="center"
                    alignItems="center"
                    width={[1 / 1, null, null, 1 / 2]}
                    order={[1, null, null, 0]}
                  >
                    <FlexCol
                      mt={[64, null, null, 32]}
                      mb={[64, null, null, 0]}
                      mx={[32, null, null, 0]}
                    >
                      <FlexCol
                        width={1 / 1}
                        maxWidth={[480, null, null, 330]}
                        ml={[0, null, null, -48]}
                        mr={[-16, null, null, 0]}
                        mb={[-16, null, null, -24]}
                      >
                        <StickerStamp
                          title={`From the`}
                          children={page.wordpressPage.acf.starting_price}
                        />
                      </FlexCol>
                      <ContentStack
                        heading={page.wordpressPage.acf.heading}
                        body={page.wordpressPage.acf.body}
                        buttonText={`Register for more info`}
                        buttonLink={
                          process.env.NODE_ENV !== 'development'
                            ? '/nude/contact'
                            : '/contact'
                        }
                      />
                    </FlexCol>
                  </FlexCol>
                  <FlexCol
                    width={[1 / 1, null, null, 1 / 2]}
                    height={['85vh', null, null, '100%']}
                  >
                    <Gallery
                      images={page.wordpressPage.acf.images}
                      active={props.active}
                      id="amenities2"
                    />
                  </FlexCol>
                </FlexGrid>
              </Box>
            </Box>
          )}
      </>
    )}
  />
)

export default AmenityTwo
