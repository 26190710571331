import React from 'react'
import { Location } from '@reach/router'
import { StaticQuery, graphql } from 'gatsby'

import { Box, FlexGrid, ThreeSixty, Dialog } from '../../components'

import FloorUnit from './components/floors'

class FloorplansComp extends React.Component {
  render() {
    return (
      <Location>
        {location => (
          <StaticQuery
            query={graphql`
              {
                wordpressPage(slug: { eq: "availability" }) {
                  acf {
                    floor {
                      floor_name
                      unit_01_sold
                      unit_02_sold
                      unit_03_sold
                      unit_04_sold
                      unit_05_sold
                      unit_06_sold
                      unit_07_sold
                      unit_08_sold
                      unit_09_sold
                      unit_10_sold
                    }
                  }
                }
                views: wordpressPage(slug: { eq: "views" }) {
                  acf {
                    views {
                      view_image {
                        source_url
                      }
                      view_bottom
                      view_top
                    }
                  }
                }
              }
            `}
            render={page => (
              <Floorplans page={page} location={location} {...this.props} />
            )}
          />
        )}
      </Location>
    )
  }
}

class Floorplans extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      floorIndex: 0,
      activeFloor: {},
      activeUnit: '10',
      mobileLocation: '',
      activeView: -1,
      activeViewPosition: 0,
    }
  }

  componentDidMount() {
    this.findFirst()
  }

  findFirst = () => {
    if (this.props.page.wordpressPage.acf.floor[0]) {
      let floorFound = false
      let floorIndex = 0
      let unit = '01'
      this.props.page.wordpressPage.acf.floor.forEach((floor, index) => {
        if (!floorFound) {
          if (
            floor.unit_01_sold === false ||
            floor.unit_02_sold === false ||
            floor.unit_03_sold === false ||
            floor.unit_04_sold === false ||
            floor.unit_05_sold === false ||
            floor.unit_06_sold === false ||
            floor.unit_07_sold === false ||
            floor.unit_08_sold === false ||
            floor.unit_09_sold === false ||
            floor.unit_10_sold === false
          ) {
            floorFound = true
            floorIndex = index
            if (floor.unit_10_sold === false) {
              unit = '10'
            }
            if (floor.unit_09_sold === false) {
              unit = '09'
            }
            if (floor.unit_08_sold === false) {
              unit = '08'
            }
            if (floor.unit_07_sold === false) {
              unit = '07'
            }
            if (floor.unit_06_sold === false) {
              unit = '06'
            }
            if (floor.unit_05_sold === false) {
              unit = '05'
            }
            if (floor.unit_04_sold === false) {
              unit = '04'
            }
            if (floor.unit_03_sold === false) {
              unit = '03'
            }
            if (floor.unit_02_sold === false) {
              unit = '02'
            }
            if (floor.unit_01_sold === false) {
              unit = '01'
            }
          }
        }
      })
      this.setState({
        activeFloor: this.props.page.wordpressPage.acf.floor[floorIndex],
        activeUnit: unit,
        floorIndex: floorIndex,
      })
    }
  }

  changeUnit = (unit, changeRoute) => {
    this.setState({ activeUnit: unit }, () => {
      if (changeRoute) {
        let title =
          this.state.activeFloor.floor_name +
          this.state.activeUnit +
          ' | Battistella NUDE'
        window.history.replaceState(
          null,
          title,
          (process.env.NODE_ENV !== 'development' ? '/nude' : '') +
            '/floorplans-' +
            this.state.activeFloor.floor_name.replace('Floor ', '') +
            this.state.activeUnit
        )
        this.setState({
          mobileLocation:
            (process.env.NODE_ENV !== 'development' ? '/nude' : '') +
            '/floorplans-' +
            this.state.activeFloor.floor_name.replace('Floor ', '') +
            this.state.activeUnit,
        })
        this.changeMetaTags(title)
        setTimeout(() => {
          if (process.env.NODE_ENV !== 'development') {
            this.track()
          }
        }, 200)
        if (this.props.isMobile) {
          let element = document.getElementById('unitView')
          setTimeout(() => {
            if (element) {
              element.scrollIntoView()
              window.scrollBy(0, -80) // Adjust scrolling with a negative value here
            }
          }, 30)
        }
      }
    })
  }

  changeFloor = (floorIndex, changeRoute) => {
    this.setState(
      {
        floorIndex: floorIndex,
        activeFloor: this.props.page.wordpressPage.acf.floor[floorIndex],
      },
      () => {
        let title =
          this.state.activeFloor.floor_name +
          this.state.activeUnit +
          ' | Battistella NUDE'
        if (changeRoute) {
          window.history.replaceState(
            null,
            title,
            (process.env.NODE_ENV !== 'development' ? '/nude' : '') +
              '/floorplans-' +
              this.state.activeFloor.floor_name.replace('Floor ', '') +
              this.state.activeUnit
          )
          this.changeMetaTags(title)
          this.setState({
            mobileLocation:
              (process.env.NODE_ENV !== 'development' ? '/nude' : '') +
              '/floorplans-' +
              this.state.activeFloor.floor_name.replace('Floor ', '') +
              this.state.activeUnit,
          })
          setTimeout(() => {
            if (process.env.NODE_ENV !== 'development') {
              this.track()
            }
          }, 200)
        }
      }
    )
  }

  changeMetaTags = title => {
    document.title = title
    document
      .querySelector('meta[property="og:title"]')
      .setAttribute('content', title)
    document
      .querySelector('meta[property="twitter:title"]')
      .setAttribute('content', title)
  }

  track = () => {
    setTimeout(() => {
      window.LassoCRM = window.LassoCRM || {}
      ;(function(ns) {
        ns.tracker = new window.LassoAnalytics('LAS-515532-07')
      })(window.LassoCRM)
      try {
        window.LassoCRM.tracker.setTrackingDomain('//app.lassocrm.com')
        window.LassoCRM.tracker.init() // initializes the tracker
        window.LassoCRM.tracker.track() // track() records the page visit with the current page title, to record multiple visits call repeatedly.
        window.LassoCRM.tracker.patchRegistrationForms()
      } catch (error) {}
    }, 200)
  }

  showView = (view, initialPosition) => {
    let foundView = -1
    let targetView = parseInt(this.state.floorIndex) + 3
    let position = 0
    if (initialPosition) {
      position = initialPosition
    }
    if (!view) {
      this.props.page.views.acf.views.forEach((view, index) => {
        if (view.view_bottom <= targetView && view.view_top >= targetView) {
          foundView = index
        }
      })
    }
    if (view && view !== -1000) {
      foundView = view
    }
    this.setState({ activeView: foundView, activeViewPosition: position })
  }

  render() {
    return (
      <Box
        height="100%"
        bg="bg.default"
        id={
          process.env.NODE_ENV !== 'development'
            ? '/nude/floorplans'
            : '/floorplans'
        }
      >
        <Box height="100%">
          {this.state.activeView !== -1 && (
            <Dialog
              close={() => {
                this.showView(-1000)
              }}
              naked={true}
            >
              <Box height="85vh">
                <ThreeSixty
                  initialPosition={this.state.activeViewPosition}
                  controlLabel={
                    this.props.page.views.acf.views[this.state.activeView]
                      .view_bottom +
                    ' - ' +
                    this.props.page.views.acf.views[this.state.activeView]
                      .view_top
                  }
                  previousDisabled={this.state.activeView === 0}
                  previous={() => {
                    this.showView(this.state.activeView - 1)
                  }}
                  next={() => {
                    this.showView(this.state.activeView + 1)
                  }}
                  nextDisabled={
                    this.state.activeView >=
                    this.props.page.views.acf.views.length - 1
                  }
                >
                  <img
                    src={
                      this.props.page.views.acf.views[this.state.activeView]
                        .view_image.source_url
                    }
                    alt=""
                    style={{ height: '100%' }}
                  />
                </ThreeSixty>
              </Box>
            </Dialog>
          )}
          <FlexGrid height="100%">
            <FloorUnit
              mobileLocation={this.state.mobileLocation}
              floorIndex={this.state.floorIndex}
              activeFloor={this.state.activeFloor}
              activeUnit={this.state.activeUnit}
              floors={this.props.page.wordpressPage.acf.floor}
              changeFloor={this.changeFloor}
              changeUnit={this.changeUnit}
              showView={this.showView}
            />
          </FlexGrid>
        </Box>
      </Box>
    )
  }
}
export default FloorplansComp
