import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { Box, Button, H2, H5, FlexGrid, FlexCol } from '../../../components'

import { UnitImage } from './style'

const Unit = props => {
  // const prices = {
  //   '01': '$284,100',
  //   '03': '$322,600',
  //   '04': '$445,600',
  //   '05': '$283,100',
  //   '06': '$283,100',
  //   '08': '$322,600',
  //   '09': '$441,700',
  //   '06': '$301,900',
  //   '07': '$387,200',
  //   '08': '$369,100',
  //   '09': '$451,000',
  //   '10': '$283,100',
  // }

  return (
    <StaticQuery
      query={graphql`
        {
          wordpressPage(slug: { eq: "floorplans" }) {
            acf {
              units {
                unit_number
                unit_bed___bath
                unit_sq_footage
                unit_floorplan {
                  localFile {
                    childImageSharp {
                      sizes(maxWidth: 1900, quality: 10) {
                        ...GatsbyImageSharpSizes_withWebp_tracedSVG
                      }
                    }
                  }
                }
                unit_pdf {
                  url {
                    source_url
                  }
                }
                price
              }
            }
          }
        }
      `}
      render={page => (
        <Box
          width={1 / 1}
          px={[24, null, null, 96]}
          minHeight="100%"
          id="unitView"
        >
          {page.wordpressPage.acf.units.map((unit, index) => {
            if (unit.unit_number === props.activeUnit) {
              return (
                <React.Fragment key={'unit' + index}>
                  <Box mt={[0, null, null, 64]}>
                    <FlexGrid justifyContent="space-between" gutterX={[2]}>
                      <Box width={[1 / 1, null, null, 'initial']}>
                        <H5
                          children={'Delivering in 2024'}
                          display="inline"
                          style={{ verticalAlign: 'middle' }}
                          mb={1}
                        />
                        <H2
                          children={
                            `Unit ` +
                            (props.activeFloor.floor_name
                              ? props.activeFloor.floor_name.replace(
                                  'Floor',
                                  ''
                                )
                              : '') +
                            unit.unit_number
                          }
                          textAlign={['center', null, null, 'initial']}
                        />
                        <FlexCol
                          alignItems={['center', null, null, 'flex-start']}
                          width={[1 / 1, null, null, 'initial']}
                        >
                          <Box mb={1}>
                            <H5
                              children={unit.unit_bed___bath}
                              display="inline"
                              style={{ verticalAlign: 'middle' }}
                            />
                            <Box
                              display="inline-block"
                              width="1px"
                              height="20px"
                              mx={2}
                              bg="bg.alt"
                              style={{ verticalAlign: 'middle' }}
                            />
                            <H5
                              children={unit.unit_sq_footage + ` sq ft`}
                              display="inline"
                              style={{ verticalAlign: 'middle' }}
                            />
                          </Box>
                          {/* <H5
                            children={
                              prices[unit.unit_number]
                                ? `Starting from ${prices[unit.unit_number]}`
                                : 'SOLD'
                            }
                            display="inline"
                            style={{ verticalAlign: 'middle' }}
                            mb={1}
                          /> */}
                        </FlexCol>
                      </Box>
                      <Box display={['none', null, null, 'initial']}>
                        <FlexGrid gutterY={[2]}>
                          <Box width={1} style={{ paddingBottom: '0.5rem' }}>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={unit.unit_pdf.url.source_url}
                              children={`Download floor plan`}
                              style={{
                                color: '#333740',
                                background: 'none',
                                borderBottom: '2px solid #333740',
                                fontSize: '24px',
                                fontFamily: `"Knockout 27 A", "Knockout 27 B", Knockout, sans-serif`,
                                textTransform: 'uppercase',
                              }}
                            />
                          </Box>
                          <Box width={1} style={{ paddingBottom: '0.5rem' }}>
                            <a
                              children={`See the view`}
                              onClick={() => {
                                props.showView(
                                  undefined,
                                  index === 0 || index === 9
                                    ? -45
                                    : index === 1 || index === 2 || index === 3
                                      ? -315
                                      : index === 4 || index === 5
                                        ? -215
                                        : -130
                                )
                              }}
                              style={{
                                color: '#333740',
                                background: 'none',
                                borderBottom: '2px solid #333740',
                                fontSize: '24px',
                                fontFamily: `"Knockout 27 A", "Knockout 27 B", Knockout, sans-serif`,
                                textTransform: 'uppercase',
                                cursor: 'pointer',
                              }}
                            />
                          </Box>
                          <Box width={1}>
                            <Button
                              children={`Register for more info`}
                              appearance="nude"
                              is="a"
                              href={
                                process.env.NODE_ENV !== 'development'
                                  ? '/nude/contact'
                                  : '/contact'
                              }
                            />
                          </Box>
                        </FlexGrid>
                      </Box>
                    </FlexGrid>
                  </Box>
                  <FlexCol justifyContent="center" alignItems="center">
                    <UnitImage
                      width={1 / 1}
                      maxWidth={800}
                      height="60vh"
                      mx="auto"
                    >
                      <Img
                        sizes={
                          unit.unit_floorplan.localFile.childImageSharp.sizes
                        }
                        style={{
                          width: '100%',
                          height: '100%',
                          pointerEvents: 'none',
                          userSelect: 'none',
                        }}
                        imgStyle={{ objectFit: 'contain' }}
                        outerWrapperClassName="customImage"
                      />
                    </UnitImage>
                  </FlexCol>
                  <Box display={['initial', null, null, 'none']}>
                    <Box pt={[5, 7, null, 0]}>
                      <FlexGrid gutterY={[2]}>
                        <FlexCol alignItems="center" width={1 / 1}>
                          <Button
                            is="a"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={unit.unit_pdf.url.source_url}
                            children={`Download floor plan`}
                            appearance="reverse"
                          />
                        </FlexCol>
                        <FlexCol alignItems="center" width={1 / 1}>
                          <Button
                            children={`See the view`}
                            appearance="reverse"
                            onClick={() => {
                              props.showView(
                                undefined,
                                index === 0 || index === 9
                                  ? -45
                                  : index === 1 || index === 2 || index === 3
                                    ? -315
                                    : index === 4 || index === 5
                                      ? -215
                                      : -130
                              )
                            }}
                          />
                        </FlexCol>
                        <FlexCol alignItems="center" width={1 / 1}>
                          <Button
                            children={`Register for more info`}
                            appearance="nude"
                            is="a"
                            href={
                              process.env.NODE_ENV !== 'development'
                                ? '/nude/contact'
                                : '/contact'
                            }
                          />
                        </FlexCol>
                      </FlexGrid>
                    </Box>
                  </Box>
                </React.Fragment>
              )
            } else {
              return null
            }
          })}
        </Box>
      )}
    />
  )
}

export default Unit
