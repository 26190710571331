import styled from 'styled-components'
import { lighten } from 'polished'
import { FlexCol } from '../../../components'

export const FloorplanImage = styled.img`
  width: 100%;
  max-width: 100%;
  height: 100%;
  vertical-align: middle;
  user-select: none;
  pointer-events: none;
  object-fit: contain;
`

export const FloorplanSvg = styled.svg`
  width: 100%;
  max-width: 100%;
  fill: ${props => props.theme.colors.bg.alt};
  stroke: ${props => props.theme.colors.bg.default};
  stroke-width: 2px;
  vertical-align: middle;
  opacity: 1;
`

export const HoverPath = styled.path`
  opacity: 0;
  transition: opacity 700ms cubic-bezier(0.4, 0, 0, 1);
  fill: ${props => props.theme.colors.brand.salmon};
  cursor: pointer;

  &:hover {
    opacity: 0.67;
  }
  &.clicked {
    opacity: 0.9;
  }
  &.sold {
    pointer-events: none;
    opacity: 0.9;
    fill: ${props => lighten(0.55, props.theme.colors.bg.reverse)};
  }
`

export const HoverRect = styled.rect`
  opacity: 0;
  transition: opacity 700ms cubic-bezier(0.4, 0, 0, 1);
  fill: ${props => props.theme.colors.brand.salmon};
  cursor: pointer;

  &:hover {
    opacity: 0.67;
  }
  &.clicked {
    opacity: 0.9;
  }
  &.sold {
    pointer-events: none;
    opacity: 0.9;
    fill: ${props => lighten(0.55, props.theme.colors.bg.reverse)};
  }
`

export const UnitImage = styled(FlexCol)`
  .customImage {
    width: 100%;
    height: 100%;
  }
`
