import React from 'react'
import styled from 'styled-components'

const CustomSvg = styled.svg`
  text-align: left;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 72px;
  @media only screen and (min-width: 52em) {
    max-height: 96px;
  }
  vertical-align: middle;
`

const Unicorn = props => (
  <CustomSvg width={540} height={567} viewBox="0 0 540 567" {...props}>
    <path
      d="M364.639 235.553c4.635 4.905 4.416 12.638-.49 17.272-4.904 4.635-12.636 4.416-17.271-.489-4.635-4.904-4.416-12.637.488-17.272 4.906-4.634 12.638-4.415 17.273.489zm153.837 96.074c-5.789 5.471-14.916 5.212-20.386-.577-5.471-5.788-5.212-14.916.576-20.385l19.81 20.962zM256.348 305.45c-8.919 39.015 29.197 66.56 67.981 58.854 2.892-.574 5.776-1.188 8.7-1.558l116.174-14.721s7.805 32.335 35.191 33.11c27.384.776 51.777-40.614 51.777-40.614l-18.366-47.859-177.24-116.375c-20.276-13.312-59.124-1.673-59.124-1.673s18.233-14.309 19.221-49.208c.988-34.899-33.653-70.806-33.653-70.806l-3.383 119.509c-43.15-1.221-83.249 12.522-115.318 36.525-34.422 25.765-52.346 66.462-61.634 110.499-7.112 33.718-8.304 68.416-4.778 102.695L85.203 563h317.873l-94.158-181.189-52.57-76.361zm95.971-168.179L480.359 3l-96.724 154.871-31.316-20.6zM82.12 349.326s-57.526 12.609-41.286 95.132C57.073 526.981 4 525.336 4 525.336l78.12-176.01zM38.067 411.96S9.315 253.742 110.376 254.837L38.067 411.96zm34.326-148.193s21.169-96.226 109.989-73.296L72.393 263.767zm73.89-76.447s47.78-58.889 117.899-32.837L146.283 187.32zm150.45-37.879s66.159-45.454 99.643 29.91c21.066 47.414 57.856 20.357 57.856 20.357l-157.499-50.267zm88.11-46.275l11.081 35.027-11.081-35.027zm31.496-33.03l9.067 20.852-9.067-20.852z"
      stroke="#FF9C71"
      strokeWidth={8}
      fill="none"
      fillRule="evenodd"
      strokeLinejoin="bevel"
    />
  </CustomSvg>
)

export default Unicorn
