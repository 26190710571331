import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import { Box, ContentStack, FlexCol, FlexGrid, Gallery } from '../../components'

const AmenityOne = props => (
  <StaticQuery
    query={graphql`
      {
        wordpressPage(slug: { eq: "amenity-one" }) {
          acf {
            heading
            body
            icon {
              source_url
            }
            images {
              localFile {
                childImageSharp {
                  sizes(maxWidth: 1900, quality: 90) {
                    ...GatsbyImageSharpSizes_withWebp
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={page => (
      <Box
        height="100%"
        bg="bg.default"
        id={
          process.env.NODE_ENV !== 'development'
            ? '/nude/building'
            : '/building'
        }
      >
        <Box height="100%">
          <FlexGrid height="100%">
            <FlexCol
              width={[1 / 1, null, null, 1 / 2]}
              height={['85vh', null, null, '100%']}
              flex={1}
            >
              <Gallery
                images={page.wordpressPage.acf.images}
                active={props.active}
                id="amenities1"
              />
            </FlexCol>
            <FlexCol
              justifyContent="center"
              alignItems="center"
              width={[1 / 1, null, null, 1 / 2]}
              order={[1, null, null, 0]}
            >
              <FlexCol
                mt={[64, null, null, 32]}
                mb={[64, null, null, 0]}
                mx={[32, null, null, 0]}
              >
                <ContentStack
                  image={page.wordpressPage.acf.icon.source_url}
                  heading={page.wordpressPage.acf.heading}
                  body={page.wordpressPage.acf.body}
                  buttonText={`Register for more info`}
                  buttonLink={
                    process.env.NODE_ENV !== 'development'
                      ? '/nude/contact'
                      : '/contact'
                  }
                />
              </FlexCol>
            </FlexCol>
          </FlexGrid>
        </Box>
      </Box>
    )}
  />
)

export default AmenityOne
