import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import { Box, FlexCol, FlexGrid, Gallery } from '../../components'

const GalleryComp = props => (
  <StaticQuery
    query={graphql`
      {
        wordpressPage(slug: { eq: "gallery" }) {
          acf {
            gallery_1 {
              source_url
              localFile {
                childImageSharp {
                  sizes(maxWidth: 1900, quality: 90) {
                    ...GatsbyImageSharpSizes_withWebp
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={page => (
      <Box height="100%" bg="bg.default">
        <Box height="100%">
          <FlexGrid height="100%">
            <FlexCol
              width={[1 / 1, null, null, 1 / 1]}
              height={['85vh', null, null, '100%']}
            >
              <Gallery
                images={page.wordpressPage.acf.gallery_1}
                active={props.active}
                id="gallery1"
                infinite={true}
                bookShowing
              />
              {/* <Gallery
                images={page.wordpressPage.acf.gallery_2}
                active={props.active}
                id="gallery2"
              /> */}
            </FlexCol>
          </FlexGrid>
        </Box>
      </Box>
    )}
  />
)

export default GalleryComp
