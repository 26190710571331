import React from 'react'
import styled from 'styled-components'

import { Box, Button, FlexCol, FlexRow, H3 } from '../../components'

const LI = styled.li`
  margin-bottom: 15px;
  &::marker {
    color: #ff9c71;
  }
`

const Investors = () => (
  <FlexRow
    height="100%"
    bg="bg.default"
    id={
      process.env.NODE_ENV !== 'development' ? '/nude/investors' : '/investors'
    }
    flexDirection={['column', 'column', 'row', 'row']}
  >
    <FlexCol
      width={['100%', '100%', '50%', '50%']}
      height={['50rem', '50rem', '100%', '100%']}
      style={{
        backgroundImage: `url("${
          process.env.NODE_ENV !== 'development'
            ? '/nude/images/investors_bg.jpg'
            : '/images/investors_bg.jpg'
        }")`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    />
    <FlexCol
      width={['100%', '100%', '50%', '50%']}
      height="100%"
      style={{ padding: '5rem 5% 2rem', justifyContent: 'center' }}
    >
      <img
        src={
          process.env.NODE_ENV !== 'development'
            ? '/nude/images/dollar_icon.png'
            : '/images/dollar_icon.png'
        }
        alt=""
        width={'87px'}
        height={'88px'}
        style={{ marginBottom: '1rem' }}
      />
      <H3>{`Why Nude, and Calgary, is right for your next Investment.`}</H3>
      <ol type={1} style={{ paddingInlineStart: '1rem', marginBlockEnd: 0 }}>
        <LI>
          {`Voted #1 most Livable City in Canada and #1 most Livable City in North America`}
        </LI>
        <LI>
          {`Calgary has the most affordable living costs for any major metropolitan centre in Canada.`}
        </LI>
        <LI>
          {`Most attractive real estate investment cycle, with low interest rates, lower vacancy rates & rising rents continuing.`}
        </LI>
        <LI>
          {`Exceptional Investor Program, with on site rental management services available.`}
        </LI>
        <LI>
          {`The only High Rise condo development in downtown Calgary set for completion in the next 3 years.`}
        </LI>
        <LI>
          {`Outstanding location just steps away from Calgary’s vibrant Red mile.`}
        </LI>
        <LI>{`Highest head office concentration per capita in Canada`}</LI>
        <LI>
          {`Huge tech sector growth & new jobs/economic diversification is accelerating`}
        </LI>
        <LI>
          {`Will provide a long term tenant for the first year and Short term rental friendly.`}
        </LI>
      </ol>
      <p
        style={{ marginBottom: '1.5rem' }}
      >{`Ask us how you can secure your unit with only 5% down.`}</p>
      <Box>
        <Button
          children={`Register for more info`}
          appearance="nude"
          is="a"
          href={
            process.env.NODE_ENV !== 'development'
              ? '/nude/contact'
              : '/contact'
          }
        />
      </Box>
    </FlexCol>
  </FlexRow>
)

export default Investors
