import styled, { keyframes } from 'styled-components'

import { FlexCol } from '../../components'

export const FadeCol = styled(FlexCol)`
  opacity: 1;
  transition: opacity 300ms cubic-bezier(0.4, 0, 0, 1);

  &.hidden {
    opacity: 0;
  }
`

export const WordWrapper = styled.span`
  display: block;
  overflow: hidden;
`

const textSlideIn = keyframes`
  0% {
    transform: translate3d(0, 110%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
`

export const Word = styled.span`
  display: block;
  transform: translate3d(0, 110%, 0);
  animation: 900ms ${textSlideIn} cubic-bezier(0.4, 0, 0, 1) forwards;
  animation-delay: 200ms;

  ${WordWrapper}:nth-child(2) & {
    animation-delay: 400ms;
  }
  ${WordWrapper}:nth-child(3) & {
    animation-delay: 600ms;
  }
  ${WordWrapper}:nth-child(4) & {
    animation-delay: 800ms;
  }
  ${WordWrapper}:nth-child(5) & {
    animation-delay: 1000ms;
  }
`
