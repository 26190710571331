import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { Box, ContentStack, FlexCol, FlexGrid, Footer } from '../../components'

const Company = props => (
  <StaticQuery
    query={graphql`
      {
        wordpressPage(slug: { eq: "builder" }) {
          acf {
            logo {
              source_url
            }
            builder_image {
              localFile {
                childImageSharp {
                  sizes(maxWidth: 1900, quality: 90) {
                    ...GatsbyImageSharpSizes_withWebp
                  }
                }
              }
            }
            builder_button_text
            builder_button_link
            builder_heading
            builder_body
          }
        }
      }
    `}
    render={page => (
      <Box
        height="100%"
        bg="bg.default"
        id={
          process.env.NODE_ENV !== 'development' ? '/nude/builder' : '/builder'
        }
      >
        <FlexCol height="100%">
          <FlexCol flex={1}>
            <FlexGrid flex={1} width={1 / 1}>
              <FlexCol
                justifyContent="center"
                alignItems="center"
                width={[1 / 1, null, null, 1 / 2]}
                order={[1, null, null, 0]}
              >
                <FlexCol
                  mt={[64, null, null, 32]}
                  mb={[64, null, null, 0]}
                  mx={[32, null, null, 0]}
                >
                  <Box width={1 / 1} maxWidth={330} mb={4}>
                    <img
                      src={page.wordpressPage.acf.logo.source_url}
                      alt=""
                      style={{
                        width: '160px',
                        maxWidth: '100%',
                        verticalAlign: 'middle',
                      }}
                    />
                  </Box>
                  <ContentStack
                    heading={page.wordpressPage.acf.builder_heading}
                    body={page.wordpressPage.acf.builder_body}
                    buttonText={page.wordpressPage.acf.builder_button_text}
                    buttonLink={page.wordpressPage.acf.builder_button_link}
                    builder
                  />
                </FlexCol>
              </FlexCol>
              <Box
                position="relative"
                width={[1 / 1, null, null, 1 / 2]}
                height={['67vh', null, null, '100%']}
              >
                <Img
                  sizes={
                    page.wordpressPage.acf.builder_image.localFile
                      .childImageSharp.sizes
                  }
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    userSelect: 'none',
                    pointerEvents: 'none',
                  }}
                  imgStyle={{
                    objectPosition: 'top left',
                  }}
                />
              </Box>
            </FlexGrid>
          </FlexCol>
          <Footer />
        </FlexCol>
      </Box>
    )}
  />
)

export default Company
