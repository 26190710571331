import React from 'react'

import { Box, FlexCol, Select } from '../../../components'

import InteractiveFloor from './interactiveFloor'
import Unit from './unit'

class Floors extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Box
          width={[1 / 1, null, null, 1 / 2]}
          height={['auto', null, null, '100%']}
          bg="bg.default"
        >
          <FlexCol
            justifyContent="center"
            alignItems="center"
            height="100%"
            pt={64}
          >
            <Box width={1 / 1} px={[24, null, null, 96]}>
              <FlexCol alignItems="center" width={1 / 1}>
                <Select
                  value={this.props.floorIndex}
                  onChange={event => {
                    this.props.changeFloor(event.target.value, true)
                  }}
                >
                  {this.props.floors.map((floor, index) => (
                    <option
                      key={floor.floor_name}
                      children={floor.floor_name}
                      value={index}
                    />
                  ))}
                </Select>
              </FlexCol>
              <FlexCol
                justifyContent="center"
                alignItems="center"
                mt={[5, null, null, 7]}
                mb={[5, 7, null, 0]}
              >
                <InteractiveFloor
                  activeUnit={this.props.activeUnit}
                  activeFloor={this.props.activeFloor}
                  changeUnit={this.props.changeUnit}
                />
              </FlexCol>
            </Box>
          </FlexCol>
        </Box>
        <Box
          width={[1 / 1, null, null, 1 / 2]}
          height={['auto', null, null, '100%']}
          bg="bg.wash"
        >
          <FlexCol
            justifyContent="center"
            alignItems="center"
            height="100%"
            pt={64}
            pb={64}
          >
            <Unit
              activeFloor={this.props.activeFloor}
              activeUnit={this.props.activeUnit}
              showView={this.props.showView}
            />
          </FlexCol>
        </Box>
      </React.Fragment>
    )
  }
}

export default Floors
