import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'

import {
  Box,
  FlexCol,
  FlexGrid,
  H2,
  Text,
  Button,
  Gallery,
} from '../../components'

import Unicorn from './components/unicorn'

class BigYear extends React.Component {
  changeRoute = path => {
    if (this.props.isMobile) {
      let element = document.getElementById(path)
      setTimeout(() => {
        if (element) {
          element.scrollIntoView()
          // window.LassoCRM.tracker.track()
          window.history.replaceState(null, null, path)
        }
      }, 30)
    } else {
      this.props.forceRoute(path)
    }
  }
  render() {
    return (
      <StaticQuery
        query={graphql`
          {
            wordpressPage(slug: { eq: "big-year" }) {
              acf {
                heading
                subheading
                body
                button_text
                big_year_active
                images {
                  localFile {
                    childImageSharp {
                      sizes(maxWidth: 1900, quality: 90) {
                        ...GatsbyImageSharpSizes_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={page => (
          <Box
            height="100%"
            bg="bg.default"
            id={
              process.env.NODE_ENV !== 'development'
                ? '/nude/live-free'
                : '/live-free'
            }
          >
            <Box height="100%">
              <FlexGrid height="100%">
                <FlexCol
                  justifyContent="center"
                  alignItems="center"
                  width={[1 / 1, null, null, 1 / 2]}
                >
                  <FlexCol
                    mt={[64, null, null, 32]}
                    mb={[64, null, null, 0]}
                    mx={[32, null, null, 0]}
                  >
                    <Box
                      width={1 / 1}
                      maxWidth={[480, null, null, 400]}
                      position="relative"
                    >
                      <Unicorn />
                      {page.wordpressPage.acf.heading && (
                        <H2 children={page.wordpressPage.acf.heading} mt={3} />
                      )}
                      {page.wordpressPage.acf.subheading && (
                        <Text
                          dangerouslySetInnerHTML={{
                            __html: page.wordpressPage.acf.subheading,
                          }}
                          mt={3}
                          fontWeight="bold"
                        />
                      )}
                      <Text
                        dangerouslySetInnerHTML={{
                          __html: page.wordpressPage.acf.body,
                        }}
                        mt={3}
                      />
                      <Box mt={5}>
                        <Button
                          children={page.wordpressPage.acf.button_text}
                          appearance="alt"
                          onClick={() => {
                            localStorage.setItem('lassoSourceType', 'BIG YEAR')
                            this.changeRoute(
                              process.env.NODE_ENV !== 'development'
                                ? '/nude/register'
                                : '/register'
                            )
                          }}
                        />
                      </Box>
                    </Box>
                  </FlexCol>
                </FlexCol>
                <FlexCol
                  width={[1 / 1, null, null, 1 / 2]}
                  height={['85vh', null, null, '100%']}
                  flex={1}
                >
                  <Gallery
                    images={page.wordpressPage.acf.images}
                    active={this.props.active}
                    id="bigYear"
                  />
                </FlexCol>
              </FlexGrid>
            </Box>
          </Box>
        )}
      />
    )
  }
}

export default BigYear
