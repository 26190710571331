import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import {
  Box,
  FlexCol,
  H2,
  H5,
  Text,
  Carousel,
  Feature,
  Button,
  FlexRow,
  Pan,
  MapModal,
  Switch,
} from '../../components'

const FeatureItem = props => (
  <Box
    width={1 / 1}
    maxWidth={288}
    minWidth={288}
    mx={32}
    py={[64, null, null, '5vw']}
    px={32}
    key={props.index}
    style={{ userSelect: 'none' }}
    onClick={props.onClick}
  >
    <Feature
      heading={props.item.heading}
      body={props.item.body}
      image={props.item.image.localFile.childImageSharp.sizes}
      focused={props.focused}
    />
  </Box>
)
class Neighbourhood extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mapModal: false,
      openMap: 'live',
    }
  }

  toggleMapModal = () => {
    this.setState({ mapModal: !this.state.mapModal })
  }

  toggleMap = map => {
    this.setState({ openMap: map })
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          {
            wordpressPage(slug: { eq: "neighbourhood" }) {
              acf {
                heading
                body
                button_text
                live_map {
                  source_url
                }
                button_2_text
                play_map {
                  source_url
                }
                items {
                  heading
                  body
                  image {
                    localFile {
                      childImageSharp {
                        sizes(maxWidth: 1900, quality: 10) {
                          ...GatsbyImageSharpSizes_withWebp_tracedSVG
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={page => (
          <Box
            height="100%"
            bg="bg.reverse"
            id={
              process.env.NODE_ENV !== 'development'
                ? '/nude/neighbourhood'
                : '/neighbourhood'
            }
          >
            {this.state.mapModal && (
              <MapModal close={this.toggleMapModal}>
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  right={0}
                  height="64px"
                >
                  <FlexRow alignItems="center" height="100%" ml={4}>
                    <H5 children={`Live`} />
                    <Box mx={3}>
                      <Switch
                        onClick={() => {
                          if (this.state.openMap === 'live') {
                            this.toggleMap('play')
                          } else {
                            this.toggleMap('live')
                          }
                        }}
                      />
                    </Box>
                    <H5 children={`Play`} />
                  </FlexRow>
                </Box>
                <Box
                  position="absolute"
                  top="64px"
                  left={0}
                  right={0}
                  bottom={0}
                  // height="100%"
                >
                  {this.state.openMap === 'live' && (
                    <Pan
                      image={page.wordpressPage.acf.live_map.source_url}
                      mapId="liveMap"
                    />
                  )}
                  {this.state.openMap === 'play' && (
                    <Pan
                      image={page.wordpressPage.acf.play_map.source_url}
                      mapId="playMap"
                    />
                  )}
                </Box>
              </MapModal>
            )}
            <Box height="100%">
              <FlexCol
                justifyContent="center"
                alignItems="center"
                height="100%"
                pt={[64, null, null, 32]}
                pb={[64, null, null, 0]}
              >
                <H2
                  children={page.wordpressPage.acf.heading}
                  mx={[32, null, null, 0]}
                  textAlign="center"
                  color="text.reverse"
                />
                <Text
                  children={page.wordpressPage.acf.body}
                  mt={3}
                  mx={[32, null, null, 0]}
                  textAlign="center"
                  color="text.reverse"
                />
                <FlexCol alignItems="center" width={1 / 1}>
                  {/* Desktop carousel */}
                  <FlexCol display={['none', null, null, 'flex']} width={1 / 1}>
                    <Carousel
                      carouselId="neighbourhoodCarouselLarge"
                      center={true}
                      hideButtons={false}
                      infinite={true}
                    >
                      {page.wordpressPage.acf.items.map((item, index) => (
                        <FeatureItem index={index} item={item} />
                      ))}
                    </Carousel>
                  </FlexCol>
                  {/* Mobile carousel */}
                  <FlexCol display={['flex', null, null, 'none']} width={1 / 1}>
                    <Carousel carouselId="neighbourhoodCarouselSmall">
                      {page.wordpressPage.acf.items.map((item, index) => (
                        <Box
                          width="100vw"
                          py={[64, null, null, '7.5vw']}
                          px={32}
                          key={index}
                        >
                          <Feature
                            heading={item.heading}
                            body={item.body}
                            image={item.image.localFile.childImageSharp.sizes}
                          />
                        </Box>
                      ))}
                    </Carousel>
                  </FlexCol>
                  {/* <FlexRow>
                    {page.wordpressPage.acf.live_map && (
                      <Button
                        children={page.wordpressPage.acf.button_text}
                        width={128}
                        onClick={() => {
                          this.toggleMap('live')
                          this.toggleMapModal()
                        }}
                        // is="a"
                        // href={page.wordpressPage.acf.live_map.source_url}
                        // target="_blank"
                        // rel="noopener noreferrer"
                      />
                    )}
                    {page.wordpressPage.acf.play_map && (
                      <Button
                        children={page.wordpressPage.acf.button_2_text}
                        width={128}
                        appearance="alt"
                        onClick={() => {
                          this.toggleMap('play')
                          this.toggleMapModal()
                        }}
                        // is="a"
                        // href={page.wordpressPage.acf.play_map.source_url}
                        // target="_blank"
                        // rel="noopener noreferrer"
                      />
                    )}
                    <Button children={`View map`}width={128}
                        appearance="alt"
                        onClick={() => {
                          this.toggleMap('play')
                          this.toggleMapModal()
                        }} />
                  </FlexRow> */}
                  <FlexRow>
                    <Button
                      children={`View map`}
                      width={128}
                      appearance="alt"
                      onClick={() => {
                        this.toggleMap('live')
                        this.toggleMapModal()
                      }}
                    />
                    <Box width={20} />
                    <Button
                      children={`Register for more info`}
                      appearance="nude"
                      is="a"
                      href={
                        process.env.NODE_ENV !== 'development'
                          ? '/nude/contact'
                          : '/contact'
                      }
                    />
                  </FlexRow>
                </FlexCol>
              </FlexCol>
            </Box>
          </Box>
        )}
      />
    )
  }
}

export default Neighbourhood
