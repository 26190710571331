import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import {
  Box,
  Button,
  H1,
  H2,
  H3,
  H4,
  ScrollArrow,
  FlexCol,
} from '../../components'

import { FadeCol, WordWrapper, Word } from './style'

const DoubleHigh = (props) => (
  <StaticQuery
    query={graphql`
      {
        wordpressPage(slug: { eq: "landing" }) {
          acf {
            tagline
            banner_image {
              localFile {
                childImageSharp {
                  sizes(maxWidth: 1900, quality: 90) {
                    ...GatsbyImageSharpSizes_withWebp
                  }
                }
              }
            }
            banner_image_mobile {
              localFile {
                childImageSharp {
                  sizes(maxWidth: 1900, quality: 90) {
                    ...GatsbyImageSharpSizes_withWebp
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(page) => (
      <Box
        height="100%"
        id={process.env.NODE_ENV !== 'development' ? '/nude/' : '/'}
      >
        <Box display={['none', null, null, 'initial']}>
          <Img
            sizes={
              page.wordpressPage.acf.banner_image.localFile.childImageSharp
                .sizes
            }
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '200vh',
              userSelect: 'none',
              pointerEvents: 'none',
            }}
          />
        </Box>
        <Box display={['initial', null, null, 'none']}>
          <Img
            sizes={
              page.wordpressPage.acf.banner_image_mobile.localFile
                .childImageSharp.sizes
            }
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '125vh',
              userSelect: 'none',
              pointerEvents: 'none',
            }}
            imgStyle={{
              objectPosition: 'left center',
            }}
          />
        </Box>
        <FadeCol
          position="relative"
          zIndex={1}
          alignItems="center"
          style={{
            padding: '2rem',
            margin: '16vh auto 0',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            width: 'max-content',
            maxWidth: '90%',
          }}
        >
          <H3 textAlign="center" mb={1} style={{ color: '#4891bb' }}>
            For a Limited time!
          </H3>
          <H4 textAlign="center" mb={1} style={{ color: '#4891bb' }}>
            ENJOY 2% OFF MORTGAGE INTEREST RATES FOR THE FIRST YEAR
          </H4>
          <H4 textAlign="center" mb={1} style={{ color: '#4891bb' }}>
            1-YEAR FREE CONDO FEES
          </H4>
        </FadeCol>
        <FadeCol
          position="relative"
          zIndex={1}
          alignItems="center"
          pt={['6vh', '6vh', '6vh']}
          px={32}
          height={['85%', null, null, '50%']}
          className={props.active ? '' : 'hidden'}
        >
          <H2 textAlign="center" mb={1}>
            MOVE IN READY
          </H2>
          <H1 textAlign="center" mb={1}>
            NOW RENTING!
          </H1>
          <H3 textAlign="center">&</H3>
          <H3 textAlign="center">SELLING LAST UNITS!</H3>
          <H3 textAlign="center">STARTING FROM $300,000</H3>
          <Box mt={3} display="flex" justifyContent="center">
            <Button
              children={`Register for more info`}
              appearance="nude"
              is="a"
              href={
                process.env.NODE_ENV !== 'development'
                  ? '/nude/contact'
                  : '/contact'
              }
              style={{ fontSize: '2rem' }}
            />
            <Box width={20} />
          </Box>
          <FlexCol alignItems="center" mt="auto" mb={6}>
            <ScrollArrow height={50} />
          </FlexCol>
        </FadeCol>
      </Box>
    )}
  />
)

export default DoubleHigh
